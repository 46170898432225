import React, { useState, useEffect } from 'react';
import { BsFillTrashFill, BsFillPencilFill, BsChevronDown, BsChevronUp, BsPlusCircleFill, BsClockHistory } from "react-icons/bs";
import { IoSettings } from "react-icons/io5";
import { GrDetach } from "react-icons/gr";
import { Toaster, toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import NotFound from '../../common/NotFound/index'
import { GetAllPositions, PositionItem } from '../../types/positions'
import { formatCurrency } from '../../utils/formatCurrency'
import { RiRadioButtonLine } from "react-icons/ri";
import { useDealsStatContext } from '../../context/DealsStatContext';
import Pagination from '../../components/Pagination/Pagination'
import CountPagination from './CountPagination'

interface TranStatisticsProps {
  statistics: PositionItem[];
  updateStatistics: (page: number, size: number, from: number, to: number, accounts: string[], bots: string[], symbols: string[]) => void;
  totalCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
  setStatistics: React.Dispatch<React.SetStateAction<PositionItem[]>>;
}

const TableTranStatistics: React.FC<TranStatisticsProps> = ({
  statistics,
  updateStatistics,
  totalCount,
  onPageChange,
  currentPage,
  setStatistics
}) => {

  const { pageSize } = useDealsStatContext();
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const pageCount = Math.ceil((totalCount || 0) / pageSize);

  useEffect(() => {
    // Обновляем таблицу только при изменении statistics
  }, [statistics]);

  const toggleRowExpansion = (id: string) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter(rowId => rowId !== id)
        : [...prevExpandedRows, id]
    );
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">

            <div className="grid grid-cols-12 gap-4 bg-[#F9FAFB] dark:bg-meta-4 px-5 py-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">L/S</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">SYMBOL</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">TIME CLOSE</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">AMOUNT</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">MARKET</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">ACCOUNT</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">TIME OPEN</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark ">FEE</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">NET Profit</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">

              {statistics && statistics.length === 0 || statistics === null ? (
                <NotFound />
              ) : (statistics && statistics.map((item, key) => {
                const isExpanded = expandedRows.includes(item.id);

                return (
                  <div className="border-t border-[#EEEEEE] dark:border-strokedark" key={key}>
                    <div className="grid grid-cols-12 gap-4 px-5 py-4 lg:px-7.5 2xl:px-11">

                      <div className="col-span-1 flex items-start gap-2">
                        {isExpanded ? (
                          <BsChevronUp
                            className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                            onClick={() => toggleRowExpansion(item.id)}
                          />
                        ) : (
                          <BsChevronDown
                            className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                            onClick={() => toggleRowExpansion(item.id)}
                          />
                        )}
                        <div className="col-span-2 flex items-center justify-center">
                          <p className={`inline-flex rounded-full border py-1 px-3 text-sm font-medium uppercase ${item.positionSide === 'LONG' ? 'border-[#3CA745] text-[#3CA745]' : 'border-[#FF5733] text-[#FF5733]'}`}>{item.positionSide === 'LONG' ? "L" : "S"}</p>
                        </div>
                      </div>

                      <div className="col-span-1 items-start">
                        <div className='flex items-center gap-2'>
                          <p className={`text-[#637381] dark:text-bodydark`}>{item.symbol}</p>
                        </div>
                      </div>
                      <div className="col-span-2 flex items-start">
                        {new Date(item.updateTime).toLocaleString()}
                      </div>
                      <div className={`col-span-1 flex flex-col items-start justify-start overflow-hidden transition-all duration-300`}>
                        {formatCurrency((parseFloat(item.closePositionAmt)) * parseFloat(item.avgPrice))}
                      </div>
                      <div className="col-span-1 items-start">
                        <div className='flex items-center gap-2'>
                          <p className={`text-[#637381] dark:text-bodydark`}>Futures</p>
                        </div>
                      </div>
                      <div className="col-span-2 items-start">
                        <div className='flex items-center gap-2'>
                          <p className={`text-[#637381] dark:text-bodydark`}>{item.apiKey?.name} {item.apiKey?.bot?.name}</p>
                        </div>
                      </div>
                      <div className="col-span-2 flex items-start">
                        {new Date(item.openTime).toLocaleString()}
                      </div>
                      <div className="col-span-1 flex items-start justify-start gap-4">
                        {formatCurrency(parseFloat(item.positionCommission) + parseFloat(item.totalFunding))}
                      </div>
                      <div className={`col-span-1 flex items-start justify-start gap-4 ${parseFloat(item.netProfit) > 0 ? 'text-[#3CA745]' : 'text-[#FF5733]'}`}>
                        {formatCurrency(item.netProfit)}
                      </div>
                    </div>

                    {/* Дополнительная информация */}
                    <div className={` bg-[#F9FAFB] dark:bg-meta-4 overflow-hidden overflow-y-auto transition-all duration-300 ${isExpanded ? 'max-h-[375px]' : 'max-h-0'}`}>
                      {(item?.orders || []).map((order, orderKey) => (
                        <div className="border-t border-[#EEEEEE] dark:border-strokedark grid grid-cols-12 gap-4 px-5 py-4 lg:px-7.5 2xl:px-11 bg-gray-100 dark:bg-gray-800" key={orderKey}>
                          <div className="col-span-1 flex justify-end items-start">
                            {/* <div >
                            </div> */}
                            <p className={`inline-flex rounded-full border py-1 px-3 text-sm font-medium uppercase ${order.side === 'BUY' ? 'border-[#3CA745] text-[#3CA745]' : 'border-[#FF5733] text-[#FF5733]'}`}>{order.side === 'BUY' ? "B" : "S"}</p>
                          </div>
                          <div className="col-span-1">
                            <p className={`text-[#637381] dark:text-bodydark`}>{order.symbol}</p>
                          </div>
                          <div className="col-span-2">
                            {new Date(order.time).toLocaleString()}
                          </div>
                          <div className="col-span-1">
                            {formatCurrency(parseFloat(order.executedQty) * parseFloat(order.avgPrice))}
                          </div>
                          <div className="col-span-1">
                            <p className={`text-[#637381] dark:text-bodydark`}>Futures</p>
                          </div>
                          <div className="col-span-2">
                            <p className={`text-[#637381] dark:text-bodydark`}>{item.apiKey?.bot?.name}</p>
                          </div>
                          <div className="col-span-2">
                            {new Date(order.updateTime).toLocaleString()}
                          </div>
                          <div className="col-span-1">
                            {formatCurrency(parseFloat(order.commission))}
                          </div>
                          <div className={`col-span-1 ${order.positionSide === "LONG" ? order.side == "BUY" ? 'text-[#3CA745]' : 'text-[#FF5733]' : order.side == "BUY" ? 'text-[#3CA745]' : 'text-[#FF5733]'}`}>
                            {order.positionSide === "LONG" ? order.side === "BUY" ? 'OPEN' : 'CLOSE' : order.side == "BUY" ? 'CLOSE' : 'OPEN'}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }))}

            </div>

          </div>
        </div>
      </div>

      {statistics && statistics.length > 0 &&
        <div className='flex flex-wrap items-center gap-4 mt-4'>
          <Pagination
            pageCount={pageCount}
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
          />
          <CountPagination />
        </div>
      }

    </>
  );
};

export default TableTranStatistics;