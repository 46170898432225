import React, { useState, useEffect, createElement } from 'react';
import { Toaster, toast } from 'sonner';
import { useFutureSignalContext } from '../../context/FutureSignalContext';

interface FutureSignalProps {
  // statistics: PositionItem[];
}

const TableFutureSignals: React.FC<FutureSignalProps> = ({
  // statistics,
}) => {

  const { pageSize } = useFutureSignalContext();
  const [data, setData] = useState('empty...');

  // const addShowMwssage = function(mes: any) {
  //   return createElement(
  //     'p',
  //     { className: 'text' },
  //     mes
  //   );
  // }

  const connectWS = function() {
    const socket = new WebSocket("ws://54.78.214.25:8080/ws");

    socket.onopen = function(e) {
      // alert("[open] Соединение установлено");
      setData("[open] Соединение установлено")
    };
    
    socket.onmessage = function(event) {
      // alert(`[message] Данные получены с сервера: ${event.data}`);
      setData(`[message] Данные получены с сервера: ${event.data}`);
    };
    
    socket.onclose = function(event) {
      // if (event.wasClean) {
      //   alert(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
      // } else {
      //   // например, сервер убил процесс или сеть недоступна
      //   // обычно в этом случае event.code 1006
      //   alert('[close] Соединение прервано');
      // }
      console.log('[close] Socket is closed. Reconnect will be attempted in 1 second.', event.reason);
      setTimeout(function() {
        connectWS();
      }, 1000);
    };
    
    socket.onerror = function(err) {
      alert(`[error] Socket encountered error: ${err} Closing socket`); //err.message
      socket.close();
    }
  }

  useEffect(() => {
    connectWS()
  }, [])

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full max-h-full overflow-y-auto">
          <div className="min-h-[700px]">
              <div className="grid grid-cols-1 gap-4 bg-[#F9FAFB] dark:bg-meta-4 px-5 py-4 lg:px-7.5 2xl:px-11">
                <div className="col-span-1">
                  <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">INFO</h5>
                </div>
              </div>
              <div id="subscribe" className="h-96 w-full bg-white dark:bg-boxdark px-5 py-4 lg:px-7.5 2xl:px-11">
                <p className="text-gray-600 dark:text-white">{data}</p>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableFutureSignals;