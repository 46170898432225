import axios from 'axios';
import { refreshTokens } from './refreshTokens';
import { Toaster, toast } from 'sonner';

// Функция api для работы с защищенными маршрутами, используя токены из localStorage.
const api = axios.create();

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      const status = error.response.status;

      switch (status) {
        // case 400:
        //   toast.error('Bad Request: The server could not understand the request.');
        //   break;
        case 401:
          if (!originalRequest._retry) {
            originalRequest._retry = true;

            // Попытка обновления токенов
            const isRefreshed = await refreshTokens();
            if (isRefreshed) {
              const accessToken = localStorage.getItem('accessToken');
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              return api(originalRequest);
            } else {
              // Если обновление токенов не удалось, перенаправляем пользователя на страницу логина
              handleLogout();
            }
          } else {
            // Если повторная попытка тоже не удалась, перенаправляем пользователя на страницу логина
            handleLogout();
          }
          break;
        // case 403:
        //   toast.error(`${error}`);
        //   break;
        // case 404:
        //   toast.error(`${error}`);
        //   break;
        // case 500:
        //   // toast.error(`${error}`);
        //   break;
        // case 502:
        //   toast.error(`${error}`);
        //   break;
        // case 503:
        //   toast.error(`${error}`);
        //   break;
        // case 504:
        //   toast.error(`${error}`);
        //   break;
        // default:
        //   toast.error(`${error}`);
        //   break;
      }
    }

    return Promise.reject(error);
  }
);

const handleLogout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.location.href = '/';
};

export default api;