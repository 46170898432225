import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableApiKeys from './TableApiKeys';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { BsPlusCircleFill, BsFunnelFill } from "react-icons/bs";
import { FaUndo } from "react-icons/fa";
import Key from './Key';
import { Toaster, toast } from 'sonner';
import { HiOutlineRefresh } from "react-icons/hi";
import { useApiKeysContext } from '../../context/ApiKeysContext';
import Switcher from "../../components/Switchers/Switcher";
import { linkInterval, setLinkInterval } from "../../utils/checkTimers";
import ApiKeysFilter from "./ApiKeysFilter";

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Api keys', link: '/api-keys' }
];

const ApiKeysContent: React.FC = () => {
  const [isModalSideOpen, setIsModalSideOpen] = useState(false);
  const [isActiveRefresh, setIsActiveRefresh] = useState(Boolean(Number(localStorage.getItem("autoRefresh"))));
  // let [refreshInterval, setRefreshInterval] = useState<NodeJS.Timeout | string | number | undefined | ''>('');
  
  const {
    keys,
    totalCount,
    fetchKeys,
    handlePageChange,
    currentPage,
    setKeys,
    pageSize,
    resetFilter,
    isVisibleResetFilter
  } = useApiKeysContext();

  const location = useLocation();
  const [ seconds, setSeconds ] = useState(0);
  const [isModalFilter, setIsModalFilter] = useState(false);
  let activeRefresh = localStorage.getItem("autoRefresh") ? localStorage.getItem("autoRefresh") : "1"
  let timeToRefreshQwery = 0.5 * 60 * 1000; // 30 секунд
  let refreshInterval: NodeJS.Timeout | string | number | undefined | '';

  // сработает только один раз при первой визуализации компонента
  useEffect(() => {
    if (Boolean(Number(localStorage.getItem("autoRefresh")))) {
      refreshInterval = setInterval(handleRefresh, timeToRefreshQwery)
      console.log(`Location = ${location.pathname}, set interval in first useEffect ${refreshInterval}`);
      setLinkInterval(Number(refreshInterval))
      setSeconds(30)
    }
    return () => {
      // `componentWillUnmount`
      if (refreshInterval !== "" || (linkInterval && linkInterval != 0)) {
        console.log(`Location = ${location.pathname}, clear interval in useEffect ${refreshInterval}, ${linkInterval}`);
        clearInterval(linkInterval);
        setSeconds(0)
      }
    }
  }, [])

  useEffect(() => {
    if (seconds > 0 && isActiveRefresh) {
      setTimeout(setSeconds, 1000, seconds - 1);
    } else {
      // setIsActiveRefresh(false);
      // setSeconds(30)
    }
  }, [ seconds, isActiveRefresh ]);


  const toggleModalSide = () => {
    setIsModalSideOpen(!isModalSideOpen);
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleSave = (result: boolean) => {
    if (result) {
      toast.success('Key added successfully');
      fetchKeys(currentPage, pageSize, {});
    } else {
      toast.error('Failed to add key');
    }
  };

  const handleRefresh = () => {
    fetchKeys(currentPage, pageSize, {});
    setSeconds(30)
  };

  const handleSwitcherChange = async (handler: () => void) => {
    if (activeRefresh === "0") {
      activeRefresh = "1";
      console.log(`Current location pathname is "${location.pathname}"`);
      refreshInterval = setInterval(handler, timeToRefreshQwery)
      setLinkInterval(Number(refreshInterval))
      setSeconds(30)
    } else {
      // не отправляем запрос, только меняем значение переменной на 0
      activeRefresh = "0";
      clearInterval(linkInterval);
      setLinkInterval(0)
      console.log("clearInterval in handler id = ", linkInterval);
      setSeconds(0)
    }
    localStorage.setItem("autoRefresh", `${activeRefresh}`)
    setIsActiveRefresh(Boolean(Number(activeRefresh)))
  };

  const handleResetFilter = () => {
    resetFilter();
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />
      <div className='grid grid-cols-2 flex-col items-stretch sm:flex sm:flex-row gap-4 items-start mb-4'>
        <BtnPrimary onClick={toggleModalSide} className='col-span-2 justify-center sm:justify-start'>
          <BsPlusCircleFill /> Add key
        </BtnPrimary>

        <BtnPrimary onClick={toggleModalFilter}>
          <BsFunnelFill /> Filter
        </BtnPrimary>

        {
          !isVisibleResetFilter ?
            <BtnPrimary onClick={handleResetFilter}>
              <FaUndo /> Reset Filter
            </BtnPrimary>
          : null
        }

        {!Boolean(Number(isActiveRefresh)) &&
          <BtnPrimary onClick={handleRefresh} className=''><HiOutlineRefresh /> Refresh</BtnPrimary>
        }
        <div className="flex gap-2 items-center">
          <Switcher
            isActive={Boolean(Number(isActiveRefresh))}
            onChange={() => { handleSwitcherChange(handleRefresh) }}
            id="autorefresh"
            title="Автообновление данных"
            classNameWrap=""
          />
          {Boolean(Number(isActiveRefresh)) && seconds > 0 &&
            <p className="text-[#3CA745] font-semibold">0:{('0:' + '00'+seconds).slice(-2)}</p>
          }
        </div>
      </div>

      <ModalSideRight
        isOpen={isModalFilter}
        toggleModal={toggleModalFilter}
        title="FILTER"
        content={
          <ApiKeysFilter toggleModal={toggleModalFilter} />
        }
      />

      <ModalSideRight
        isOpen={isModalSideOpen}
        toggleModal={toggleModalSide}
        title="NEW KEY"
        content={<Key toggleModal={toggleModalSide} action='add' onSave={handleSave} />}
      />

      <TableApiKeys
        keys={keys}
        totalCount={totalCount}
        fetchKeys={fetchKeys}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        setKeys={setKeys}
      />
    </>
  );
};

export default ApiKeysContent;