import axios from 'axios';
import { ApiResponse, AuthResult } from '../types/api';
import api from '../utils/api';
import { Db_Symbols } from '../types/symbols';

export const refreshTokens = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  console.log("call refresh function");
  try {
    const refreshResponse = await axios.post<ApiResponse<AuthResult>>('/api/auth/refresh', { refresh_token: refreshToken });

    if (refreshResponse.data.code === 200 && refreshResponse.data.result) {
      localStorage.setItem('accessToken', refreshResponse.data.result.accessToken);
      localStorage.setItem('refreshToken', refreshResponse.data.result.refreshToken);
      console.log('Токен доступа успешно обновлен');
      /** Проверка наличия переменной symbols в LocalStorage и ее запрос и сохранение */
      if(!localStorage.getItem('symbols'))  {
        const accessToken = localStorage.getItem('accessToken');
        const symbolsResponse = await axios.get<ApiResponse<AuthResult>>('/api/symbols', {
          headers: { Authorization: `Bearer ${accessToken}` },
        }); //запрос symbols с api

        // Искусственная задержка после получения ответа от сервера
        await new Promise(resolve => setTimeout(resolve, 1000));

        if (symbolsResponse.data.code === 200 && symbolsResponse.data.result) {
          localStorage.setItem('symbols', JSON.stringify(symbolsResponse.data.result));
          // console.log(localStorage.getItem('symbols'))
        }
        return true;
      }

      /** Проверка наличия переменных 
       * binanceFutures_symbols, bybitFutures_symbols, gateSpot_symbols, mexcSpot_symbols, bitgetSpot_symbols
       * в LocalStorage и их запрос и сохранение */
      if (!localStorage.getItem('binanceFutures_symbols')) {
        const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=binance');
        await new Promise(resolve => setTimeout(resolve, 500));
        localStorage.setItem('binanceFutures_symbols', JSON.stringify(symbolsResponse.data.result));
      }

      if (!localStorage.getItem('bybitFutures_symbols')) {
        const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=bybit');
        await new Promise(resolve => setTimeout(resolve, 500));
        debugger
        localStorage.setItem('bybitFutures_symbols', JSON.stringify(symbolsResponse.data.result));
      }

      if (!localStorage.getItem('gateSpot_symbols')) {
        const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=gate');
        await new Promise(resolve => setTimeout(resolve, 500));
        debugger
        localStorage.setItem('gateSpot_symbols', JSON.stringify(symbolsResponse.data.result));
      }

      if (!localStorage.getItem('mexcSpot_symbols')) {
        const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=mexc');
        await new Promise(resolve => setTimeout(resolve, 500));
        debugger
        localStorage.setItem('mexcSpot_symbols', JSON.stringify(symbolsResponse.data.result));
      }

      if (!localStorage.getItem('bitgetSpot_symbols')) {
        const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=bitget');
        await new Promise(resolve => setTimeout(resolve, 500));
        debugger
        localStorage.setItem('bitgetSpot_symbols', JSON.stringify(symbolsResponse.data.result));
      }
      return true;
    }
  } catch (refreshError) {    
    console.log('Не удалось обновить токен доступа');
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    // localStorage.removeItem('symbols');
    window.location.href = '/';
    return false;
  }

  return false;
};