import React, { useState, useEffect } from 'react';
import { BsFunnelFill } from "react-icons/bs";
import { FaUndo } from "react-icons/fa";
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import TableBotsAll from './TableBotsAll';
import Loader from '../../../common/Loader';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import { HiOutlineRefresh } from "react-icons/hi";
import { useBotsContext } from '../../../context/BotsAllContext';
import { useLocation } from 'react-router-dom';
import Switcher from "../../../components/Switchers/Switcher";
import { linkInterval, setLinkInterval } from "../../../utils/checkTimers";
import ModalSideRight from '../../../components/Modals/ModalSideRight';
import BotsAllFilter from './BotsAllFilter'

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Bots all', link: '/bots-all' }
];

const BotsAll: React.FC = () => {
  const {
    botsAll,
    isLoading,
    error,
    getBotsAll,
    totalCount,
    handlePageChange,
    currentPage,
    resetFilter,
    isVisibleResetFilter,
  } = useBotsContext();
  const [isActiveRefresh, setIsActiveRefresh] = useState(Boolean(Number(localStorage.getItem("autoRefresh"))));
  // let [refreshInterval, setRefreshInterval] = useState<NodeJS.Timeout | string | number | undefined | ''>('');
  const location = useLocation();
  const [ seconds, setSeconds ] = useState(0);
  const [isModalFilter, setIsModalFilter] = useState(false);
  let activeRefresh = localStorage.getItem("autoRefresh") ? localStorage.getItem("autoRefresh") : "1"
  let timeToRefreshQwery = 0.5 * 60 * 1000; // 30 секунд
  let refreshInterval: NodeJS.Timeout | string | number | undefined | '';

  // сработает только один раз при первой визуализации компонента
  useEffect(() => {
    // event.stopPropagation()
    if (Boolean(Number(localStorage.getItem("autoRefresh")))) {
      refreshInterval = setInterval(handleRefresh, timeToRefreshQwery)
      console.log(`Location = ${location.pathname}, setInterval in first useEffect ${refreshInterval}`);
      setLinkInterval(Number(refreshInterval))
      setSeconds(30)
    }
    return () => {
      // `componentWillUnmount`
      if (refreshInterval !== "" || (linkInterval && linkInterval != 0)) {
        console.log(`! Location = ${location.pathname}, clear interval in useEffect ${refreshInterval}, ${linkInterval}`);
        clearInterval(linkInterval);
        setSeconds(0)
      }
    }
  }, [])

  useEffect(() => {
      if (seconds > 0 && isActiveRefresh) {
        setTimeout(setSeconds, 1000, seconds - 1);
      } else {
        // setSeconds(30)
      }
    }, [ seconds, isActiveRefresh ]);

  const handleRefresh = () => {
    getBotsAll(currentPage, {});
    setSeconds(30)
  };

  if (isLoading) {
    return <Loader />;
  }

  const handleSwitcherChange = async (handler: () => void) => {
    if (activeRefresh === "0") {
      activeRefresh = "1";
      console.log(`Current location pathname is "${location.pathname}"`);
      refreshInterval = setInterval(handler, timeToRefreshQwery)
      setLinkInterval(Number(refreshInterval))
      setSeconds(30)
    } else {
      // не отправляем запрос, только меняем значение переменной на 0
      activeRefresh = "0";
      clearInterval(linkInterval);
      setLinkInterval(0)
      console.log("clearInterval in handler id = ", linkInterval);
      setSeconds(0)
    }
    localStorage.setItem("autoRefresh", `${activeRefresh}`)
    setIsActiveRefresh(Boolean(Number(activeRefresh)))
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleResetFilter = () => {
    resetFilter();
  };

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className=''>
        <div className="grid grid-cols-2 flex-col items-stretch sm:flex sm:flex-row gap-4 items-start mb-4">
          <BtnPrimary onClick={toggleModalFilter}>
            <BsFunnelFill /> Filter
          </BtnPrimary>

          {
            !isVisibleResetFilter ?
              <BtnPrimary onClick={handleResetFilter}>
                <FaUndo /> Reset Filter
              </BtnPrimary>
            : null
          }

          {!Boolean(Number(isActiveRefresh)) &&
            <BtnPrimary onClick={handleRefresh} className=''><HiOutlineRefresh /> Refresh</BtnPrimary>
          }
          <Switcher
            isActive={Boolean(Number(isActiveRefresh))}
            onChange={() => { handleSwitcherChange(handleRefresh) }}
            id="autorefresh"
            title="Автообновление данных"
            classNameWrap=""
          />
          {Boolean(Number(isActiveRefresh)) && seconds > 0 &&
            <p className="text-[#3CA745] font-semibold">0:{('0:' + '00'+seconds).slice(-2)}</p>
          }
        </div>
      </div>
      
      <ModalSideRight
        isOpen={isModalFilter}
        toggleModal={toggleModalFilter}
        title="FILTER"
        content={
          <BotsAllFilter toggleModal={toggleModalFilter} />
        }
      />

      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-4">
        <div className="col-span-12">
          <TableBotsAll
            bots={botsAll}
            updateBots={getBotsAll}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            currentPage={currentPage} />
        </div>
      </div>
    </>
  );
};

export default BotsAll;