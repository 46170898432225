import React, { useContext, useState, useCallback,useEffect, useRef } from 'react';
import { useApiKeysContext } from '../../context/ApiKeysContext';
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import { toast } from 'sonner';
import DatePicker from '../../components/Forms/DatePicker';
import { BsFunnelFill, BsXCircleFill } from "react-icons/bs";
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { KEY, GetApiKeysAll } from '../../types/key'
import api from '../../utils/api';
import { ApiResponse } from '../../types/api'
import { Db_Bots } from '../../types/botsAll'
import { Db_Symbols } from '../../types/symbols'
import CheckboxInput from '../../components/Forms/Inputs/CheckboxInput';
import InputField from '../../components/Forms/Inputs/InputField';

interface ApiKeysFilterProps {
  toggleModal: () => void;
}

const ApiKeysFilter: React.FC<ApiKeysFilterProps> = ({ toggleModal }) => {
  const {
    keysAll,
    currentPage,
    pageSize,
    selectedMarket,
    fetchKeys,
    // fetchKeysFiltered,
    setSelectedMarket,
    selectedFavorite,
    setSelectedFavorite,
    selectedBalance,
    setSelectedBalance,
    selectedPositions,
    setSelectedPositions,
    selectedName,
    setSelectedName,
  } = useApiKeysContext();

  const markets = [
    {
      value: "futures",
      text: "Futures",
    },
    {
      value: "spot",
      text: "Spot",
    },
  ]
  
  const [market, setMarket] = useState<string>(selectedMarket || markets[0].value)
  const [favorite, setFavorite] = useState<boolean>(selectedFavorite)
  const [searchName, setSearchName] = useState(selectedName)
  const [balance, setBalance] = useState<boolean>(selectedBalance)
  const [positions, setPositions] = useState<boolean>(selectedPositions)

  const handleMarketChange = (value: string) => {
    setMarket(value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchName(value)
  }

  const handleApplyFilter = () => {
    const filters = {
      // "botAttached": true,
      // "invalid": false,
      "market": market,
      "favorite": favorite,
      // "exchangeName": [],
      "search": searchName,
      "balance": balance,
      "positions": positions
    };
    setSelectedName(searchName);
    setSelectedMarket(market);
    setSelectedFavorite(favorite);
    setSelectedBalance(balance);
    setSelectedPositions(positions);
    fetchKeys(currentPage, pageSize, filters)
    // setIsVisibleResetFilter(true)
    toggleModal();
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow mb-4">
        {/* <SimpleSelect
          label="Exchangers"
          options={exchangers}
          value={exchanger}
          onChange={handleExchangerChange}
          className="w-full mb-4"
        /> */}
        <SimpleSelect
          label="Market"
          options={markets}
          value={market}
          onChange={handleMarketChange}
          className="w-full mb-4"
        />

        <InputField
          label="Name"
          value={searchName}
          onChange={handleNameChange}
          placeholder="Search by name..."
          className="mb-4"
        />

        <div className='flex flex-col gap-2 mb-4'>
          {
            favorite ?
            <CheckboxInput
              label="Show favourite api keys"
              value=""
              id="favorite"
              checked
              onChange={() => { setFavorite(!favorite)}}
              className=""
            />
            :
            <CheckboxInput
              label="Show favourite api keys"
              value=""
              id="favorite"
              onChange={() => { setFavorite(!favorite)}}
              className=""
            />
          }

          {
            balance ?
            <CheckboxInput
              label="Show only with balance"
              value=""
              checked
              id="balance"
              onChange={() => { setBalance(!balance)}}
              className=""
            />
            :
            <CheckboxInput
              label="Show only with balance"
              value=""
              id="balance"
              onChange={() => { setBalance(!balance)}}
              className=""
            />
          }
          
          {
            positions ?
            <CheckboxInput
              label="Show api keys with filled positions"
              value=""
              checked
              id="positions"
              onChange={() => { setPositions(!positions)}}
              className=""
            />
            :
            <CheckboxInput
              label="Show api keys with filled positions"
              value=""
              id="positions"
              onChange={() => { setPositions(!positions)}}
              className=""
            />
          }
        </div>
      </div>

      <div className='flex items-center justify-between gap-4 mt-auto'>
        <BtnPrimary
          className='w-full'
          onClick={handleApplyFilter}>
          <BsFunnelFill /> Apply
        </BtnPrimary>
        <BtnPrimary
          className='w-full'
          style="outline"
          onClick={toggleModal}>
          <BsXCircleFill /> Close
        </BtnPrimary>
      </div>

    </div>
  );
};

export default ApiKeysFilter;