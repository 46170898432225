import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import SimpleSelect from '../../../components/Forms/Select/SimpleSelect';
import { symbols, sides, ways } from '../../../data/symbols';
import { Toaster, toast } from 'sonner';
import { Db_Settings, Db_Bots } from '../../../types/botsAll';
import BtnPrimary from '../../../components/Buttons/BtnPrimary'
import { IoIosSave } from "react-icons/io";
import { ApiResponse } from '../../../types/api';
import api from '../../../utils/api';
import Loader from '../../../common/Loader';
import InputField from '../../../components/Forms/Inputs/InputField';
import Switcher from "../../../components/Switchers/Switcher";
import { TimeFrame } from '../../../data/timeFrame'
import { FaUnderline } from 'react-icons/fa6';
import { Db_Symbols } from '../../../types/symbols'
import SimpleSelectWithSearch from '../../../components/Forms/Select/SimpleSelectWithSearch';

const optionsLeverages = [
  { value: "1", text: "1" },
  { value: "10", text: "10" },
  { value: "20", text: "20" },
  { value: "25", text: "25" },
  { value: "50", text: "50" },
  { value: "75", text: "75" },
  { value: "100", text: "100" },
  { value: "125", text: "125" },
];

const optionsExchanges = [
  { value: "binance", text: "binance" },
  { value: "bingx", text: "bingx" },
  { value: "bybit", text: "bybit" },
  { value: "gate", text: "gate" },
  { value: "mexc", text: "mexc" },
  { value: "bitget", text: "bitget" },
];

const optionsMarkets = [
  { value: "futures", text: "futures" },
  { value: "spot", text: "spot" },
];

const optionsStragies = [
  { value: "TwoMashka", text: "TwoMashka" },
  { value: "AverageTwoMashka", text: "AverageTwoMashka" },
  { value: "Moonshot", text: "Moonshot" },
  { value: "Mashka4Bars", text: "Mashka4Bars" },
  { value: "JustTest", text: "JustTest" },
  { value: "Liquidation", text: "Liquidation" },
  { value: "OpenInterest", text: "OpenInterest" },
  { value: "FundingRate", text: "FundingRate" },
  { value: "AutoOI", text: "AutoOI" },
];

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Bots all', link: '/bots-all' },
  { name: 'Bot settings', link: '/bot/:botUuid' }
];

const BotSettings: React.FC = () => {
  const { botUuid } = useParams<{ botUuid: string }>();
  const [bot, setBot] = useState<Db_Bots | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const navigate = useNavigate();

  // Состояния для настроек
  const [orderVolume, setOrderVolume] = useState<number | ''>('');
  const [averageStep, setAverageStep] = useState<number | ''>('');
  const [multiplierAverageStep, setMultiplierAverageStep] = useState<number | ''>('');
  const [maximumRange, setMaximumRange] = useState<number | ''>('');
  const [stopLoseRange, setStopLoseRange] = useState<number | ''>(0);
  const [leverage, setLeverage] = useState('')
  const [symbol, setSymbol] = useState('');
  const [way, setWay] = useState('');
  const [side, setSide] = useState('');
  const [strategy, setStrategy] = useState('');
  const [timeFrame, setTimeFrame] = useState('');
  const [exchange, setExchange] = useState('');
  const [market, setMarket] = useState('');
  const [takeProfit, setTakeProfit] = useState<number | ''>(0);
  const [procentForOpen, setProcentForOpen] = useState<number | ''>(0);
  const [procentForStop, setProcentForStop] = useState<number | ''>(0);
  const [lengthEMA, setLengthEMA] = useState<number | ''>(0);
  const [initUSDT, setInitUSDT] = useState<number | ''>(0);
  const [maxCountAVG, setMaxCountAVG] = useState<number | ''>(0)
  const [procentStep, setProcentStep] = useState<number | ''>(0)
  const [delaySwap, setDelaySwap] = useState<number | ''>(0)
  const [needOpenExtra, setNeedOpenExtra] = useState<boolean>(false)

  const [isZero, setIsZero] = useState<boolean>(false)
  const [valueForOpenMore, setValueForOpenMore] = useState<number | ''>(0);
  const [valueForOpenLess, setValueForOpenLess] = useState<number | ''>(0);
  const [zeroProcentStep, setZeroProcentStep] = useState<number | ''>(0);
  const [zeroProcentFree, setZeroProcentFree] = useState<number | ''>(0);
  const [zeroProcentNoLoss, setZeroProcentNoLoss] = useState<number | ''>(0);



  // Исходные значения настроек
  const [originalSettings, setOriginalSettings] = useState<Db_Settings | null>(null);

  useEffect(() => {
    console.log("strategy: ", strategy)

    const fetchBotSettings = async () => {
      try {
        const response = await api.get<ApiResponse<Db_Bots>>(`/bot-api/bots/${botUuid}`);
        const result = response.data.result;
        if (result) {
          setBot(result);
          console.log('result stopLose: ', result.settings.stopLose)

          // Инициализация состояний настроек
          setOrderVolume(result.settings?.initVolume || 0);
          setAverageStep(result.settings?.stepAveraging || 0);
          setMultiplierAverageStep(result.settings?.multiplierStepAveraging || 0);
          setMaximumRange(result.settings?.maxUp || 0);
          setStopLoseRange(result?.settings?.stopLose || 0);
          setTakeProfit(result.settings?.takeProfit || 0);
          setProcentForOpen(result.settings?.procentForOpen || 0)
          setProcentForStop(result.settings?.procentForStop || 0)
          setLengthEMA(result.settings?.lengthEMA || 0)
          setLeverage(result.settings?.leverage.toString() || '');
          setExchange(result.api?.exchange_name.toString() || '');
          setMarket(result.api?.market.toString() || '');
          setSymbol(result?.settings?.symbol.toString() || '')
          setWay(result?.settings?.way.toString() || '')
          setSide(result?.settings?.side.toString() || '')
          setStrategy(result.settings?.strategy.toString() || '')
          setTimeFrame(result.settings?.timeFrame.toString() || '')
          setInitUSDT(result?.settings?.initUSDT || 0)
          setMaxCountAVG(result?.settings?.maxCountAVG || 0)
          setProcentStep(result?.settings?.procentStep || 0)
          setDelaySwap(result?.settings?.delaySwap || 0)
          setNeedOpenExtra(result?.settings?.needOpenExtra)
          setIsZero(result?.settings?.isZero)
          setValueForOpenMore(result?.settings?.valueForOpenMore)
          setValueForOpenLess(result?.settings?.valueForOpenLess)
          setZeroProcentStep(result?.settings?.zeroProcentStep)
          setZeroProcentFree(result?.settings?.zeroProcentFree)
          setZeroProcentNoLoss(result?.settings?.zeroProcentNoLoss)
          console.log("initUSDT: ", response.data.result?.setting);

          // Сохраняем исходные значения настроек
          setOriginalSettings(result.settings || null);

          if (result.api?.exchange_name.toUpperCase() == "BINANCE") {
            if (!localStorage.getItem('binanceFutures_symbols')) {
              const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=binance');
              await new Promise(resolve => setTimeout(resolve, 500));
              localStorage.setItem('binanceFutures_symbols', JSON.stringify(symbolsResponse.data.result));
            }
          } else if (result.api?.exchange_name.toUpperCase() == "BYBIT") {
            if (!localStorage.getItem('bybitFutures_symbols')) {
              const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=bybit');
              await new Promise(resolve => setTimeout(resolve, 500));
              localStorage.setItem('bybitFutures_symbols', JSON.stringify(symbolsResponse.data.result));
            }
          } else if (result.api?.exchange_name.toUpperCase() == "GATE") {
            if (!localStorage.getItem('gateSpot_symbols')) {
              const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=gate');
              await new Promise(resolve => setTimeout(resolve, 500));
              localStorage.setItem('gateSpot_symbols', JSON.stringify(symbolsResponse.data.result));
            }
          } else if (result.api?.exchange_name.toUpperCase() == "MEXC") {
            if (!localStorage.getItem('mexcSpot_symbols')) {
              const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=mexc');
              await new Promise(resolve => setTimeout(resolve, 500));
              localStorage.setItem('mexcSpot_symbols', JSON.stringify(symbolsResponse.data.result));
            }
          } else if (result.api?.exchange_name.toUpperCase() == "BITGET") {
            if (!localStorage.getItem('bitgetSpot_symbols')) {
              const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=bitget');
              await new Promise(resolve => setTimeout(resolve, 500));
              localStorage.setItem('bitgetSpot_symbols', JSON.stringify(symbolsResponse.data.result));
            }
          } else if (result.api?.exchange_name.toUpperCase() == "BINGX") {
            if (!localStorage.getItem('symbols')) {
              const symbolsResponse = await api.get<ApiResponse<Db_Symbols>>('/api/symbols?exchange=bingx');
              await new Promise(resolve => setTimeout(resolve, 500));
              localStorage.setItem('symbols', JSON.stringify(symbolsResponse.data.result));
            }
          }

        }
      } catch (error) {
        console.error('Error fetching bot settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBotSettings();
  }, [botUuid]);

  const handleMaximumRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaximumRange(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleIsZero = () => {
    setIsZero(!isZero);
  };

  const handleValueForOpenMore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValueForOpenMore(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };



  const handleValueForOpenLess = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValueForOpenLess(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleZeroProcentStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setZeroProcentStep(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleZeroProcentFree = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setZeroProcentFree(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleZeroProcentNoLoss = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setZeroProcentNoLoss(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  //=======
  const handleNeedOpenExtra = () => {
    setNeedOpenExtra(!needOpenExtra);
  };



  const handleLeverageChange = (value: string) => {
    setLeverage(value);
    setIsChanged(true);
  };

  const handleProcentForOpenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProcentForOpen(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleProcentStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProcentStep(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleDelaySwapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDelaySwap(value === '' ? '' : parseInt(value, 10));
    setIsChanged(true);
  };

  const handleAverageStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAverageStep(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleMaxCountAVGChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaxCountAVG(value === '' ? '' : parseInt(value, 10));
    setIsChanged(true);
  };

  const handleProcentForStopChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProcentForStop(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleTakeProfitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTakeProfit(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleStopLoseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStopLoseRange(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleLengthEMAChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLengthEMA(value === '' ? '' : parseInt(value, 10));
    setIsChanged(true);
  };

  const handleInitUSDTChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInitUSDT(value === '' ? '' : parseFloat(value));
    setIsChanged(true);
  };

  const handleStrategyChange = (value: string) => {
    setStrategy(value);
    setIsChanged(true);
  };

  const handleTimeFrameChange = (value: string) => {
    setTimeFrame(value);
    setIsChanged(true);
  };

  const handleSymbolChange = (value: string) => {
    setSymbol(value);
    setIsChanged(true);
  };

  const handleWayChange = (value: string) => {
    setWay(value);
    setIsChanged(true);
  };

  const handleSideChange = (value: string) => {
    setSide(value);
    setIsChanged(true);
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      const settings: Partial<Db_Settings> = {
        initVolume: orderVolume === '' ? undefined : Number(orderVolume),
        stepAveraging: averageStep === '' ? undefined : Number(averageStep),
        multiplierStepAveraging: multiplierAverageStep === '' ? undefined : Number(multiplierAverageStep),
        maxUp: maximumRange === '' ? undefined : Number(maximumRange),
        stopLose: stopLoseRange === '' ? undefined : Number(stopLoseRange),
        takeProfit: takeProfit === '' ? undefined : Number(takeProfit),
        leverage: leverage === '' ? undefined : Number(leverage),
        strategy: strategy === '' ? undefined : strategy,
        symbol: symbol === '' ? undefined : symbol,
        way: way === '' ? undefined : way,
        side: side === '' ? undefined : side,
        initUSDT: initUSDT === '' ? undefined : Number(initUSDT),
        maxCountAVG: maxCountAVG === '' ? undefined : Number(maxCountAVG),
        averageStep: averageStep === '' ? undefined : Number(averageStep),
        procentStep: procentStep === '' ? undefined : Number(procentStep),
        delaySwap: delaySwap === '' ? undefined : Number(delaySwap),
        procentForOpen: procentForOpen === '' ? undefined : Number(procentForOpen),
        procentForStop: procentForStop === '' ? undefined : Number(procentForStop),
        lengthEMA: lengthEMA === '' ? undefined : Number(lengthEMA),
        timeFrame: timeFrame === '' ? undefined : timeFrame,
        needOpenExtra: needOpenExtra,

        isZero: isZero,
        valueForOpenMore: valueForOpenMore === '' ? undefined : Number(valueForOpenMore),
        valueForOpenLess: valueForOpenLess === '' ? undefined : Number(valueForOpenLess),
        zeroProcentStep: zeroProcentStep === '' ? undefined : Number(zeroProcentStep),
        zeroProcentFree: zeroProcentFree === '' ? undefined : Number(zeroProcentFree),
        zeroProcentNoLoss: zeroProcentNoLoss === '' ? undefined : Number(zeroProcentNoLoss),
      };

      // Добавляем неизмененные поля из исходных настроек
      if (originalSettings) {
        Object.keys(originalSettings).forEach(key => {
          if (!(key in settings)) {
            (settings as any)[key] = (originalSettings as any)[key];
          }
        });
      }

      await api.put(`/bot-api/bots/${botUuid}`, {
        symbol: symbol,
        settings: settings
      });
      setIsChanged(false);
      toast.success(`Settings saved successfully!`);
      navigate('/bots-all');
    } catch (error) {
      console.error('Error saving bot settings:', error);
      toast.error(`${error}`);
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!bot) {
    return <div>Bot not found</div>;
  }

  console.log("stopLoseRange", stopLoseRange)

  const transformSymbolsToOptions = (symbols: Db_Symbols[]) => {
    return symbols.map(symbols => ({
      value: symbols?.symbol,
      text: symbols.symbol
    }));
  };

  let symbolData = localStorage.getItem('symbols') ? transformSymbolsToOptions(JSON.parse(localStorage.getItem('symbols') || "")) : []
  if (bot?.api?.exchange_name.toUpperCase() == "BINANCE") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('binanceFutures_symbols') || "[]"))
  } else if (bot?.api?.exchange_name.toUpperCase() == "BYBIT") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('bybitFutures_symbols') || "[]"))
  } else if (bot?.api?.exchange_name.toUpperCase() == "GATE") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('gateSpot_symbols') || "[]"))
  } else if (bot?.api?.exchange_name.toUpperCase() == "MEXC") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('mexcSpot_symbols') || "[]"))
  } else if (bot?.api?.exchange_name.toUpperCase() == "BITGET") {
    symbolData = transformSymbolsToOptions(JSON.parse(localStorage.getItem('bitgetSpot_symbols') || "[]"))
  }


  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />
      <h2 className="text-title-md2 font-semibold text-black dark:text-white mb-4">{bot.name}</h2>

      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mb-4'>
        <h3 className="text-title-md1 font-semibold text-black dark:text-white mb-4">Main settings</h3>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
          <SimpleSelect
            label='Exchange'
            options={optionsExchanges}
            value={exchange}
            onChange={() => { }}
            disabled={true}
          />
          <SimpleSelect
            label='Market'
            options={optionsMarkets}
            value={market}
            onChange={() => { }}
            disabled={true}
          />
          <SimpleSelect
            label='Leverage'
            options={optionsLeverages}
            value={leverage}
            onChange={handleLeverageChange}
          />
          <SimpleSelect
            label='Strategy'
            options={optionsStragies}
            value={strategy}
            onChange={handleStrategyChange}
          />
          {/* <SimpleSelect
            label='Symbol'
            options={[{ value: '', text: 'Select option...' }, ...symbolData]}
            value={symbol}
            onChange={handleSymbolChange}
          /> */}
          <SimpleSelectWithSearch
            className='w-full'
            label='Symbol'
            options={symbolData}
            defaultValue={symbol}
            onChange={handleSymbolChange}
          />
        </div>
      </div>

      {strategy != "Mashka4Bars" &&
        <StrategySettings
          strategyName={strategy}
          handleInitUSDTChange={handleInitUSDTChange}
          initUSDT={initUSDT}
          handleStopLoseChange={handleStopLoseChange}
          stopLose={stopLoseRange}
          handleTakeProfitChange={handleTakeProfitChange}
          takeProfit={takeProfit}
        />
      }


      {strategy === 'AverageTwoMashka' &&

        <StrategyAverageTwoMashka
          switcherNeedOpenExtra={needOpenExtra}
          setSwitcherNeedOpenExtra={setNeedOpenExtra}
          handleNeedOpenExtra={handleNeedOpenExtra}
          handleMaxCountAVGChange={handleMaxCountAVGChange}
          maxCountAVG={maxCountAVG}
          handleAverageStepChange={handleAverageStepChange}
          averageStep={averageStep} />}

      {strategy === 'Moonshot' &&
        <StrategyMoonshot
          handleProcentStepChange={handleProcentStepChange}
          procentStep={procentStep}
          handleDelaySwapChange={handleDelaySwapChange}
          delaySwap={delaySwap}
          handleSideChange={handleSideChange}
          side={side}
          handleWayChange={handleWayChange}
          way={way}
          strategy={strategy} />
      }

      {strategy === 'Liquidation' &&
        <StrategyLiquidation
          handleValueForOpenMore={handleValueForOpenMore}
          valueForOpenMore={valueForOpenMore}
          handleZeroProcentStep={handleZeroProcentStep}
          zeroProcentStep={zeroProcentStep}
          handleZeroProcentFree={handleZeroProcentFree}
          zeroProcentFree={zeroProcentFree}
          handleZeroProcentNoLoss={handleZeroProcentNoLoss}
          zeroProcentNoLoss={zeroProcentNoLoss}
          handleMaxCountAVGChange={handleMaxCountAVGChange}
          maxCountAVG={maxCountAVG}
          handleAverageStepChange={handleAverageStepChange}
          averageStep={averageStep} />
      }

      {strategy === 'AutoOI' &&
        <StrategyAutoOI
          handleZeroProcentStep={handleZeroProcentStep}
          zeroProcentStep={zeroProcentStep}
          handleZeroProcentFree={handleZeroProcentFree}
          zeroProcentFree={zeroProcentFree}
          handleZeroProcentNoLoss={handleZeroProcentNoLoss}
          zeroProcentNoLoss={zeroProcentNoLoss}
        />
      }

      {strategy === 'OpenInterest' &&
        <StrategyOpenInterest
          handleTimeFrameChange={handleTimeFrameChange}
          timeFrame={timeFrame}
          handleProcentForOpenChange={handleProcentForOpenChange}
          procentForOpen={procentForOpen}
          handleMaximumRange={handleMaximumRange}
          maximumRange={maximumRange}
          handleZeroProcentStep={handleZeroProcentStep}
          zeroProcentStep={zeroProcentStep}
          handleZeroProcentFree={handleZeroProcentFree}
          zeroProcentFree={zeroProcentFree}
          handleZeroProcentNoLoss={handleZeroProcentNoLoss}
          zeroProcentNoLoss={zeroProcentNoLoss}
          handleIsZero={handleIsZero}
          isZero={isZero}
        />
      }

      {strategy === 'FundingRate' &&
        <StrategyFundingRate
          handleValueForOpenMore={handleValueForOpenMore}
          valueForOpenMore={valueForOpenMore}
          handleValueForOpenLess={handleValueForOpenLess}
          valueForOpenLess={valueForOpenLess}
          handleZeroProcentStep={handleZeroProcentStep}
          zeroProcentStep={zeroProcentStep}
          handleZeroProcentFree={handleZeroProcentFree}
          zeroProcentFree={zeroProcentFree}
          handleZeroProcentNoLoss={handleZeroProcentNoLoss}
          zeroProcentNoLoss={zeroProcentNoLoss}
          handleMaxCountAVGChange={handleMaxCountAVGChange}
          maxCountAVG={maxCountAVG}
          handleAverageStepChange={handleAverageStepChange}
          averageStep={averageStep} />
      }

      {strategy === 'Mashka4Bars' &&
        <Mashka4Bars
          strategy={strategy}
          handleInitUSDTChange={handleInitUSDTChange}
          initUSDT={initUSDT}
          handleTimeFrameChange={handleTimeFrameChange}
          timeFrame={timeFrame}
          handleProcentForOpenChange={handleProcentForOpenChange}
          procentForOpen={procentForOpen}
          handleProcentForStopChange={handleProcentForStopChange}
          procentForStop={procentForStop}
          handleLengthEMAChange={handleLengthEMAChange}
          lengthEMA={lengthEMA}
        />}



      {strategy === 'JustTest' &&
        <StrategyMoonshot
          handleProcentStepChange={handleProcentStepChange}
          procentStep={procentStep}
          handleDelaySwapChange={handleDelaySwapChange}
          delaySwap={delaySwap}
          handleSideChange={handleSideChange}
          side={side}
          handleWayChange={handleWayChange}
          way={way}
          strategy={strategy} />}

      <div className='w-full flex justify-end mt-4'>
        <BtnPrimary
          type='submit'
          onClick={handleSaveChanges}
          disabled={!isChanged || isSaving}
        >
          {isSaving ?
            <div className='flex gap-3 items-center justify-center'>
              <span className="animate-spin">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="path-1-inside-1_1881_16183" fill="white">
                    <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" strokeWidth="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                </svg>
              </span>
              <p>Loading...</p>
            </div> : <><IoIosSave /> Save changes</>}
        </BtnPrimary>
      </div>
    </>
  );
};




interface AutoOIProps {
  zeroProcentStep: number | '',
  zeroProcentFree: number | '',
  zeroProcentNoLoss: number | '',
  handleZeroProcentStep: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentFree: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentNoLoss: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StrategyAutoOI: React.FC<AutoOIProps> = ({
  handleZeroProcentStep,
  zeroProcentStep,
  handleZeroProcentFree,
  zeroProcentFree,
  handleZeroProcentNoLoss,
  zeroProcentNoLoss,
}) => {
  return (
    <>
      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>

          <InputField
            label="Procent start check OI"
            value={zeroProcentStep}
            onChange={handleZeroProcentStep}
            placeholder="Procent start check OI"
            type="number"
            className="w-full"
          />
          <InputField
            label="Zero Procent"
            value={zeroProcentNoLoss}
            onChange={handleZeroProcentNoLoss}
            placeholder="Zero Procent"
            type="number"
            className="w-full"
          />
          {/* <div className='flex items-center gap-2'>
            <InputField
              label="Zero Procent Free Coins"
              value={zeroProcentFree}
              onChange={handleZeroProcentFree}
              placeholder="Zero Procent Free Coins"
              type="number"
              className="w-full"
            />
            <InputField
              label="Zero Procent After Free"
              value={zeroProcentNoLoss}
              onChange={handleZeroProcentNoLoss}
              placeholder="Zero Procent After Free"
              type="number"
              className="w-full"
            />
          </div> */}
        </div>
      </div>
    </>
  )
}




interface LiquidationProps {
  valueForOpenMore: number | '',
  zeroProcentStep: number | '',
  zeroProcentFree: number | '',
  zeroProcentNoLoss: number | '',
  handleValueForOpenMore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentStep: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentFree: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentNoLoss: (e: React.ChangeEvent<HTMLInputElement>) => void;

  maxCountAVG: number | '',
  handleMaxCountAVGChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  averageStep: number | '',
  handleAverageStepChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StrategyLiquidation: React.FC<LiquidationProps> = ({
  handleValueForOpenMore,
  valueForOpenMore,
  handleZeroProcentStep,
  zeroProcentStep,
  handleZeroProcentFree,
  zeroProcentFree,
  handleZeroProcentNoLoss,
  zeroProcentNoLoss,
  handleMaxCountAVGChange,
  maxCountAVG,
  handleAverageStepChange,
  averageStep
}) => {
  return (
    <>
      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
          <InputField
            label="Signal Value More"
            value={valueForOpenMore}
            onChange={handleValueForOpenMore}
            placeholder="Signal Value More"
            type="number"
            className="w-full"
          />
          <div className='flex items-center gap-2'>
            <InputField
              label="MaxCountAVG"
              value={maxCountAVG}
              onChange={handleMaxCountAVGChange}
              placeholder="MaxCountAVG"
              type="number"
              className="w-full"
            />
            <InputField
              label="AverageStep"
              value={averageStep}
              onChange={handleAverageStepChange}
              placeholder="AverageStep"
              type="number"
              className="w-full"
            />
          </div>
        </div>
      </div>
      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>

          <InputField
            label="Zero Procent Step"
            value={zeroProcentStep}
            onChange={handleZeroProcentStep}
            placeholder="Zero Procent Step"
            type="number"
            className="w-full"
          />
          <div className='flex items-center gap-2'>
            <InputField
              label="Zero Procent Free Coins"
              value={zeroProcentFree}
              onChange={handleZeroProcentFree}
              placeholder="Zero Procent Free Coins"
              type="number"
              className="w-full"
            />
            <InputField
              label="Zero Procent After Free"
              value={zeroProcentNoLoss}
              onChange={handleZeroProcentNoLoss}
              placeholder="Zero Procent After Free"
              type="number"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </>
  )
}



interface OpenInterestProps {
  isZero: boolean,
  zeroProcentStep: number | '',
  zeroProcentFree: number | '',
  zeroProcentNoLoss: number | '',
  maximumRange: number | '',
  procentForOpen: number | '',
  timeFrame: string,

  handleZeroProcentStep: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentFree: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentNoLoss: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMaximumRange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleIsZero: () => void,
  handleProcentForOpenChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTimeFrameChange: (value: string) => void;

}

const StrategyOpenInterest: React.FC<OpenInterestProps> = ({
  handleTimeFrameChange,
  timeFrame,
  handleProcentForOpenChange,
  procentForOpen,
  handleMaximumRange,
  maximumRange,
  handleZeroProcentStep,
  zeroProcentStep,
  handleZeroProcentFree,
  zeroProcentFree,
  handleZeroProcentNoLoss,
  zeroProcentNoLoss,
  handleIsZero,
  isZero,
}) => {
  return (
    <>
      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
          <SimpleSelect
            label='Time frame'
            options={TimeFrame}
            value={timeFrame}
            onChange={handleTimeFrameChange}
          />
          <InputField
            label="Procent for open"
            value={procentForOpen === '' ? '' : procentForOpen}
            onChange={handleProcentForOpenChange}
            placeholder="Procent for open"
            type="number"
            className="w-full"
          />
          <InputField
            label=" Max. Dinamic Price"
            value={maximumRange}
            onChange={handleMaximumRange}
            placeholder=" Max. Dinamic Price"
            type="number"
            className="w-full"
          />
        </div>
      </div>
      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 items-end'>
          <div className='flex gap-1 items-center'>
            <Switcher
              isActive={isZero}
              onChange={handleIsZero}
              id="need-open-extra"
            />
            <p className='text-md text-black dark:text-white font-medium'>Active No Loss</p>
          </div>
          {isZero && (
            <>
              <InputField
                label="Zero Procent Step"
                value={zeroProcentStep}
                onChange={handleZeroProcentStep}
                placeholder="Zero Procent Step"
                type="number"
                className="w-full"
              />
              <InputField
                label="Zero Procent Free Coins"
                value={zeroProcentFree}
                onChange={handleZeroProcentFree}
                placeholder="Zero Procent Free Coins"
                type="number"
                className="w-full"
              />
              <InputField
                label="Zero Procent After Free"
                value={zeroProcentNoLoss}
                onChange={handleZeroProcentNoLoss}
                placeholder="Zero Procent After Free"
                type="number"
                className="w-full"
              />
            </>
          )}

        </div>
      </div>
    </>
  )
}


interface FundingRateProps {
  valueForOpenMore: number | '',
  valueForOpenLess: number | '',
  zeroProcentStep: number | '',
  zeroProcentFree: number | '',
  zeroProcentNoLoss: number | '',
  handleValueForOpenMore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleValueForOpenLess: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentStep: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentFree: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleZeroProcentNoLoss: (e: React.ChangeEvent<HTMLInputElement>) => void;

  maxCountAVG: number | '',
  handleMaxCountAVGChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  averageStep: number | '',
  handleAverageStepChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StrategyFundingRate: React.FC<FundingRateProps> = ({
  handleValueForOpenMore,
  valueForOpenMore,
  handleValueForOpenLess,
  valueForOpenLess,
  handleZeroProcentStep,
  zeroProcentStep,
  handleZeroProcentFree,
  zeroProcentFree,
  handleZeroProcentNoLoss,
  zeroProcentNoLoss,
  handleMaxCountAVGChange,
  maxCountAVG,
  handleAverageStepChange,
  averageStep
}) => {
  return (
    <>
      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
          <InputField
            label="Signal Value More"
            value={valueForOpenMore}
            onChange={handleValueForOpenMore}
            placeholder="Signal Value More"
            type="number"
            className="w-full"
          />
          <InputField
            label="Signal Value Less"
            value={valueForOpenLess}
            onChange={handleValueForOpenLess}
            placeholder="Signal Value Less"
            type="number"
            className="w-full"
          />
          <div className='flex items-center gap-2'>
            <InputField
              label="MaxCountAVG"
              value={maxCountAVG}
              onChange={handleMaxCountAVGChange}
              placeholder="MaxCountAVG"
              type="number"
              className="w-full"
            />
            <InputField
              label="AverageStep"
              value={averageStep}
              onChange={handleAverageStepChange}
              placeholder="AverageStep"
              type="number"
              className="w-full"
            />
          </div>
        </div>
      </div>
      <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>

          <InputField
            label="Zero Procent Step"
            value={zeroProcentStep}
            onChange={handleZeroProcentStep}
            placeholder="Zero Procent Step"
            type="number"
            className="w-full"
          />
          <div className='flex items-center gap-2'>
            <InputField
              label="Zero Procent Free Coins"
              value={zeroProcentFree}
              onChange={handleZeroProcentFree}
              placeholder="Zero Procent Free Coins"
              type="number"
              className="w-full"
            />
            <InputField
              label="Zero Procent After Free"
              value={zeroProcentNoLoss}
              onChange={handleZeroProcentNoLoss}
              placeholder="Zero Procent After Free"
              type="number"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </>
  )
}

interface StrategySettingsProps {
  strategyName: string,
  handleInitUSDTChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initUSDT: number | ''
  handleStopLoseChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  stopLose: number | '',
  handleTakeProfitChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  takeProfit: number | ''
}

const StrategySettings: React.FC<StrategySettingsProps> = ({
  strategyName,
  handleInitUSDTChange,
  initUSDT,
  handleStopLoseChange,
  stopLose,
  handleTakeProfitChange,
  takeProfit,
}) => {
  console.log("stopLose", stopLose)
  return (
    <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark'>
      <h3 className="text-title-md1 font-semibold text-black dark:text-white mb-4">Settings for strategy {strategyName}</h3>

      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
        <InputField
          label="Init USDT"
          value={initUSDT === '' ? '' : initUSDT}
          onChange={handleInitUSDTChange}
          placeholder="Init USDT"
          type="number"
          className="w-full"
        />
        <InputField
          label="Stop Loss"
          value={stopLose === '' ? '' : stopLose}
          onChange={handleStopLoseChange}
          placeholder="Stop Loss"
          type="number"
          className="w-full"
        />
        <InputField
          label="Take Profit"
          value={takeProfit}
          onChange={handleTakeProfitChange}
          placeholder="Take Profit"
          type="number"
          className="w-full"
        />
        {/* {strategyName != "AutoOI" && (
          <InputField
            label="Take Profit"
            value={takeProfit}
            onChange={handleTakeProfitChange}
            placeholder="Take Profit"
            type="number"
            className="w-full"
          />
        )} */}
      </div>
    </div>
  )
}

interface StrategyProps {
  switcherNeedOpenExtra: boolean,
  setSwitcherNeedOpenExtra: (boolean: boolean) => void,
  handleNeedOpenExtra: () => void,
  maxCountAVG: number | '',
  handleMaxCountAVGChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  averageStep: number | '',
  handleAverageStepChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StrategyAverageTwoMashka: React.FC<StrategyProps> = ({
  switcherNeedOpenExtra,
  setSwitcherNeedOpenExtra,
  handleNeedOpenExtra,
  handleMaxCountAVGChange,
  maxCountAVG,
  handleAverageStepChange,
  averageStep }) => {

  return (
    <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
        <InputField
          label="MaxCountAVG"
          value={maxCountAVG}
          onChange={handleMaxCountAVGChange}
          placeholder="MaxCountAVG"
          type="number"
          className="w-full"
        />
        <InputField
          label="AverageStep"
          value={averageStep}
          onChange={handleAverageStepChange}
          placeholder="AverageStep"
          type="number"
          className="w-full"
        />
        {/* <div className='flex gap-3 items-center'>
          <Switcher
            isActive={switcherNeedOpenExtra}
            onChange={handleNeedOpenExtra}
            id="need-open-extra"
          />
          <p className='text-md text-black dark:text-white font-medium'>NeedOpenExtra</p>
        </div> */}
      </div>
    </div>
  )
}

interface MoonshotProps {
  procentStep: number | '',
  handleProcentStepChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelaySwapChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  delaySwap: number | '',
  handleSideChange: (value: string) => void,
  side: string,
  handleWayChange: (value: string) => void,
  way: string,
  strategy: string;
}

const StrategyMoonshot: React.FC<MoonshotProps> = ({
  handleProcentStepChange,
  procentStep,
  handleDelaySwapChange,
  delaySwap,
  handleSideChange,
  side,
  handleWayChange,
  way,
  strategy
}) => {
  return (
    <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark mt-4'>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
        <InputField
          label="ProcentStep"
          value={procentStep}
          onChange={handleProcentStepChange}
          placeholder="ProcentStep"
          type="number"
          className="w-full"
        />
        {strategy != "JustTest" && (
          <>
            <InputField
              label="DelaySwap (sec)"
              value={delaySwap}
              onChange={handleDelaySwapChange}
              placeholder="DelaySwap (sec)"
              type="number"
              className="w-full"
            />
            <div className='flex items-center gap-2'>
              <SimpleSelect
                label='Side'
                options={sides}
                value={side}
                onChange={handleSideChange}
                className="w-full"
              />
              <SimpleSelect
                label='Way'
                options={ways}
                value={way}
                onChange={handleWayChange}
                className="w-full"
              />
            </div>
          </>
        )}

      </div>
    </div>
  )
}

interface Mashka4BarsProps {
  strategy: string;
  handleInitUSDTChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initUSDT: number | '',
  handleProcentForOpenChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  procentForOpen: number | '',
  timeFrame: string,
  handleTimeFrameChange: (value: string) => void;
  procentForStop: number | '',
  handleProcentForStopChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  lengthEMA: number | '',
  handleLengthEMAChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Mashka4Bars: React.FC<Mashka4BarsProps> = ({
  strategy,
  handleInitUSDTChange,
  initUSDT,
  timeFrame,
  handleTimeFrameChange,
  procentForOpen,
  handleProcentForOpenChange,
  procentForStop,
  handleProcentForStopChange,
  handleLengthEMAChange,
  lengthEMA
}) => {
  return (
    <div className='rounded-lg border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark'>
      <h3 className="text-title-md1 font-semibold text-black dark:text-white mb-4">Settings for strategy {strategy}</h3>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 items-end'>
        <InputField
          label="Init USDT"
          value={initUSDT === '' ? '' : initUSDT}
          onChange={handleInitUSDTChange}
          placeholder="Init USDT"
          type="number"
          className="w-full"
        />
        <SimpleSelect
          label='Time frame'
          options={TimeFrame}
          value={timeFrame}
          onChange={handleTimeFrameChange}
        />
        <InputField
          label="Procent for open"
          value={procentForOpen === '' ? '' : procentForOpen}
          onChange={handleProcentForOpenChange}
          placeholder="Procent for open"
          type="number"
          className="w-full"
        />
        <InputField
          label="Procent for stop"
          value={procentForStop === '' ? '' : procentForStop}
          onChange={handleProcentForStopChange}
          placeholder="Procent for stop"
          type="number"
          className="w-full"
        />
        <InputField
          label="Length EMA"
          value={lengthEMA === '' ? '' : lengthEMA}
          onChange={handleLengthEMAChange}
          placeholder="Procent for stop"
          type="number"
          className="w-full"
        />
      </div>
    </div>
  )
}

export default BotSettings;