import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import './ModalBottom.css'; 
import { useApiKeysContext } from '../../context/ApiKeysContext';
import { useBotsContext } from '../../context/BotsAllContext';
import { KEY } from '../../types/key';
import { Db_Bots } from '../../types/botsAll';

interface ModalBottomProps {
  isOpen: boolean;
  toggleModal: () => void;
  content: React.ReactNode;
  data: {
    type: string;
    value: any;
  }
}

const ModalBottom = ({ isOpen, toggleModal, content, data }: ModalBottomProps) => {
  const nodeRef = useRef(null);
  // const {
  //     handleDeleteKey,
  //     handleEditKey,
  //     handleHistoryOrdersKey,
  //     handleTransferOpen,
  //     handleFavoriteKey
  //   } = useApiKeysContext();

    // const {

    // } = useBotsContext();

  // const navigate = useNavigate();
  
    // const handleNavigateToManualTrading = () => {
    //   if (data.type === "key" && !data?.value.bot?.settings?.symbol || data?.value.bot?.settings?.symbol == "") {
    //     let sym = "BTC-USDT"
    //     if (data?.value.exchange_name.toUpperCase() == "BINGX") {
    //       sym = "BTC-USDT"
    //     } else if (data?.value.exchange_name.toUpperCase() == "BYBIT") {
    //       sym = "BTCUSDT"
    //     } else if (data?.value.exchange_name.toUpperCase() == "GATE") {
    //       sym = "BTC_USDT"
    //     } else if (data?.value.exchange_name.toUpperCase() == "MEXC") {
    //       sym = "BTCUSDT"
    //     } else if (data?.value.exchange_name.toUpperCase() == "BITGET") {
    //       sym = "BTCUSDT"
    //     } else if (data?.value.exchange_name.toUpperCase() == "BINANCE") {
    //       sym = "BTCUSDT"
    //     }
    //     navigate(`/manual-trading/${data?.value.id}/${sym}`);
    //   } else {
    //     navigate(`/manual-trading/${data.value.id}/${data?.value.bot?.settings?.symbol}`);
    //   }
    // };
    // useEffect(() => {
    //   console.log(`props`,data);
    // }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="Confirm Modal"
      className="absolute bottom left-1/2 transform -translate-x-1/2 -translate-y-[1rem]  dark:border-strokedark rounded-xl outline-none p-0 md:p-5 max-w-lg w-full"
      overlayClassName="fixed top-0 right-0 left-0 z-9999 flex justify-center items-end w-full h-full bg-black transition-opacity bg-opacity-25"
      appElement={document.getElementById('root') || undefined}
    >
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="modal"
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div ref={nodeRef} className="modal-content">
          {
            data && data.type === "key" && data.value ?
              <ul className="c-content-menu flex flex-col items-stretch rounded-xl overflow-hidden">
                {content}
                {/* <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                  <span
                    className="flex w-full p-4 justify-center"
                    onClick={() => {toggleModal(); handleTransferOpen(data?.value);}}
                  >Transfer balance</span>
                </li>
                <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                  <span
                    className="flex w-full p-4 justify-center"
                    onClick={() => {toggleModal(); handleHistoryOrdersKey(data.value)}}
                  >History Orders</span>
                </li>
                <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                  <span
                    className="flex w-full p-4 justify-center"
                    onClick={handleNavigateToManualTrading}
                  >Manual trading</span>
                </li>
                <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                  <span
                    className="flex w-full p-4 justify-center"
                    onClick={() => {toggleModal(); console.log(`edit modal`, data); handleEditKey(data.value)}}
                  >Edit key</span>
                </li>
                <li className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer">
                  <span
                    className="flex w-full p-4 justify-center"
                    onClick={() => {toggleModal(); handleDeleteKey(data.value)}}
                  >Delete key</span>
                </li>

                <li
                  className="w-full border-b border-solid bg-white dark:bg-[#5A616B]  outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary cursor-pointer"
                  onClick={() => {toggleModal(); handleFavoriteKey(data.value)}}
                >
                  <span className="flex w-full p-4 justify-center">to Favorites</span>
                </li> */}
                
                <li
                  className="w-full border-b border-solid bg-white dark:bg-[#5A616B] outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary rounded-xl mt-2 cursor-pointer"
                  onClick={toggleModal}
                >
                  <span className="flex w-full p-4 justify-center">Cancel</span>
                </li>
              </ul>

            : data && data.type === "bot" && data.value ?

              <ul className="c-content-menu flex flex-col items-stretch rounded-xl overflow-hidden">
                {content}
                
                <li
                  className="w-full border-b border-solid bg-white dark:bg-[#5A616B] outline-none dark:text-white hover:text-primary hover:bg-gray-2 dark:border-strokedark dark:hover:bg-primary rounded-xl mt-2 cursor-pointer"
                  onClick={toggleModal}
                >
                  <span className="flex w-full p-4 justify-center">Cancel</span>
                </li>
              </ul>
            : null
          }
        </div>
      </CSSTransition>
    </Modal> 
  );
};

export default ModalBottom;


