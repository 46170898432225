import React from 'react';
import { FutureSignalProvider } from '../../context/FutureSignalContext';
import FutureSignalContent from './FutureSignalContent';

const ApiKeys: React.FC = () => {
  return (
    <FutureSignalProvider>
      <FutureSignalContent />
    </FutureSignalProvider>
  );
};

export default ApiKeys;