import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../utils/api';
import { ApiResponse } from '../types/api';

interface FutureSignalContextProps {
  startDate: number;
  isLoading: boolean;
  pageSize: number;
}

const FutureSignalContext = createContext<FutureSignalContextProps | undefined>(undefined);

export const useFutureSignalContext = () => {
  const context = useContext(FutureSignalContext);
  if (!context) {
    throw new Error('useFutureSignalContext must be used within a FutureSignalProvider');
  }
  return context;
};

interface FutureSignalProviderProps {
  children: React.ReactNode;
}

export const FutureSignalProvider: React.FC<FutureSignalProviderProps> = ({ children }) => {

  const startDate = 0;
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(() => {
      const savedPageSize = localStorage.getItem('futureSingalPageSize');
      return savedPageSize ? parseInt(savedPageSize, 10) : 25;
    });

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    // localStorage.setItem('dealsPageSize', pageSize.toString());
  }, []);


  const contextValue: FutureSignalContextProps = {
    startDate,
    isLoading,
    pageSize,
  };

  return (
    <FutureSignalContext.Provider value={contextValue}>
      {children}
    </FutureSignalContext.Provider>
  );
};