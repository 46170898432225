import React from 'react';

interface CheckboxInputProps {
  label: string;
  value: string | number;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({ label, value, id, onChange, className, checked = false, disabled = false }) => (
  <div className={className}>
    <label className="flex items-center gap-3 font-medium text-[#637381] mb-2.5 dark:text-white">
      {
        checked ?
          <input
            type="checkbox"
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            checked
            className={`
              rounded-lg border-[1.5px] border-stroke bg-transparent p-3 pr-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary 
              ${disabled ? 'disabled:cursor-default disabled:bg-whiter dark:disabled:bg-black dark:border-form-strokedark dark:bg-form-input  dark:focus:border-primary disabled:text-[#637381]' : ''}
            `}
          />
        :
        <input
          type="checkbox"
          value={value}
          id={id}
          onChange={onChange}
          disabled={disabled}
          className={`
            rounded-lg border-[1.5px] border-stroke bg-transparent p-3 pr-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary 
            ${disabled ? 'disabled:cursor-default disabled:bg-whiter dark:disabled:bg-black dark:border-form-strokedark dark:bg-form-input  dark:focus:border-primary disabled:text-[#637381]' : ''}
          `}
        />
      }
      <span className="flex-grow">{label}</span>
    </label>
  </div> 
);

export default CheckboxInput;