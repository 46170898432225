import React, { useRef } from 'react';
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import './ModalCenter.css'; 
import { KEY, InfoBingX, AccountSpot } from "../../types/key";
import { formatAmount } from "../../utils/formatAmount";

interface ModalMoreBalanceProps {
  isOpen: boolean;
  toggleModal: () => void;
  content: React.ReactNode;
  dataBalance: KEY | null;
}

const ModalMoreBalance = ({ isOpen, toggleModal, content, dataBalance }: ModalMoreBalanceProps) => {
  const nodeRef = useRef(null);

  let accountInfo: InfoBingX | undefined
      if (dataBalance?.exchange_name.toUpperCase() == "BYBIT") {
        accountInfo = dataBalance.infoBybit;
      } else if (dataBalance?.exchange_name.toUpperCase() == "GATE") {
        accountInfo = dataBalance.infoGate;
      } else if (dataBalance?.exchange_name.toUpperCase() == "MEXC") {
        accountInfo = dataBalance.infoMexc;
      } else if (dataBalance?.exchange_name.toUpperCase() == "BITGET") {
        accountInfo = dataBalance.infoBitget;
      } else if (dataBalance?.exchange_name.toUpperCase() == "BINANCE") {
        accountInfo = dataBalance.infoBinance;
      } else {
        accountInfo = dataBalance?.infoBingX;
      }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="Confirm Modal"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:border-strokedark dark:bg-boxdark rounded-lg shadow-lg p-4 md:p-5 max-w-lg w-full"
      overlayClassName="fixed top-0 right-0 left-0 z-9999 flex justify-center items-center w-full h-full bg-black transition-opacity bg-opacity-25"
      appElement={document.getElementById('root') || undefined}
    >
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="modal"
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div ref={nodeRef} className="modal-content">
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={toggleModal}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div>
            <h3 className="font-medium text-bodydark1 text-center mb-7">Balance:</h3>
            {/* <ul className=''>
            {dataBalance?.market.toUpperCase() != "FUTURES" ?
              (
                (accountInfo?.accountSpot || []).map((item, index) => (
                  item.asset !== "USDT" ?
                  (
                    // <li><p className="text-[#637381] dark:text-bodydark">{item.asset}: {item.free} / {item.locked}</p></li>
                    <li><p className="text-[#637381] dark:text-bodydark">{item.asset}: {formatAmount(item.free)} / {item.locked}</p></li>
                  )
                  : null
                ))
              )
              : null
            }
                </ul> */}
            <ul className="grid grid-cols-2 gap-4 md:grid-cols-2 md:gap-4 xl:grid-cols-2 2xl:gap-7.5 max-h-[500px] overflow-y-auto shadow-scroll">
            {dataBalance?.market.toUpperCase() != "FUTURES" ?
              (
                (accountInfo?.accountSpot || []).map((item, index) => (
                  item.asset !== "USDT" ?
                  (
                    <li className="rounded-lg border border-stroke bg-white py-4 px-4 shadow-default dark:border-strokedark dark:bg-boxdark">
                      <div className="mt-2 flex flex-col items-start justify-between">
                          <h4 className="text-title-xs  font-bold text-black dark:text-white">{formatAmount(item.free)}</h4>
                          <p className="text-sm font-medium">{item.asset}</p>
                      </div>
                    </li>
                    )
                    : null
                  ))
                )
                : null
              }
            </ul>
          </div>
        </div>
      </CSSTransition>
    </Modal> 
  );
};

export default ModalMoreBalance;


