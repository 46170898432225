import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableFutureSignals from './TableFutureSignal'
import { useFutureSignalContext } from '../../context/FutureSignalContext';
import Loader from '../../common/Loader/index'

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Signal Future', link: '/future-signal' }
];

const FutureSignal: React.FC = () => {
  const {
    isLoading,
    startDate,
  } = useFutureSignalContext();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-2">
        <div className="col-span-12">
          <TableFutureSignals />
        </div>
      </div>
    </>
  );
};

export default FutureSignal;